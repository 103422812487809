import { FC } from 'react';

interface IconDribbbleProps {
    className?: string;
    fill?: boolean;
    duotone?: boolean;
}

const IconDribbble: FC<IconDribbbleProps> = ({ className, fill = false, duotone = true }) => {
    return (
        <>
            {!fill ? (
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
                    <path
                        d="M3.33946 16.9997C6.10089 21.7826 12.2168 23.4214 16.9997 20.66C18.9493 19.5344 20.3765 17.8514 21.1962 15.9286C22.3875 13.1341 22.2958 9.83304 20.66 6.99972C19.0242 4.1664 16.2112 2.43642 13.1955 2.07088C11.1204 1.81935 8.94932 2.21386 6.99972 3.33946C2.21679 6.10089 0.578039 12.2168 3.33946 16.9997Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                    />
                    <path
                        opacity={duotone ? '0.5' : '1'}
                        d="M16.9497 20.5732C16.9497 20.5732 16.0107 13.9821 14.0004 10.5001C11.99 7.01803 7.05018 3.42681 7.05018 3.42681M7.57711 20.8175C9.05874 16.3477 16.4525 11.3931 21.8635 12.5801M16.4139 3.20898C14.926 7.63004 7.67424 12.5123 2.28857 11.4516"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                    />
                </svg>
            ) : (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
                    <path
                        d="M13.4525 8.39737C15.1812 6.92813 16.5396 5.18645 17.1247 3.44812L17.1351 3.41699C18.5508 4.26236 19.7768 5.47026 20.6598 6.99965C21.5426 8.52866 21.9757 10.1939 22.0001 11.8422C20.0045 11.4109 17.8139 11.7412 15.7443 12.5213C15.4235 11.6576 15.0594 10.8344 14.6498 10.125C14.3136 9.54268 13.9035 8.96187 13.4525 8.39737Z"
                        fill="currentColor"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.33931 16.9997C4.22245 18.5293 5.44869 19.7374 6.86469 20.5827L6.86513 20.5814C7.68322 18.1134 10.065 15.6355 12.8569 13.9367C13.3429 13.6409 13.8473 13.3652 14.3647 13.1148C14.0626 12.2923 13.7245 11.5222 13.3508 10.875C13.0527 10.3586 12.6827 9.83536 12.2695 9.31938C11.9235 9.56655 11.5679 9.80234 11.2053 10.025C8.44759 11.7181 5.09262 12.7682 2.14358 12.1874L1.99902 12.1589C2.02377 13.8066 2.45687 15.4712 3.33931 16.9997Z"
                        fill="currentColor"
                    />
                    <g opacity={duotone ? '0.5' : '1'}>
                        <path
                            d="M16.2258 13.9425C18.1134 13.2236 20.0374 12.9473 21.7027 13.3127L21.9074 13.3576C21.7856 14.2418 21.5463 15.1071 21.1961 15.9286C20.4717 17.6278 19.2729 19.1396 17.6585 20.2451L17.6461 20.166C17.6158 19.9748 17.5705 19.699 17.5099 19.3578C17.3887 18.6757 17.2059 17.7295 16.9592 16.672C16.7602 15.8192 16.5166 14.8803 16.2258 13.9425Z"
                            fill="currentColor"
                        />
                        <path
                            d="M8.31858 3.45405C8.06654 3.25473 7.86131 3.09745 7.71833 2.98945L7.69597 2.97258C9.45842 2.13001 11.3642 1.84893 13.1953 2.07088C14.0827 2.17844 14.9525 2.40412 15.7799 2.74129L15.703 2.96973C15.2258 4.38768 14.0529 5.92038 12.4746 7.26043C11.7442 6.46537 10.9739 5.7288 10.2721 5.09932C9.51489 4.42018 8.82226 3.85238 8.31858 3.45405Z"
                            fill="currentColor"
                        />
                        <path
                            d="M10.4204 8.74678C7.83379 10.3349 4.87003 11.1956 2.43341 10.7157L2.09082 10.6483C2.4601 7.95488 3.91941 5.43745 6.28139 3.7952L6.60934 4.03361L6.61138 4.0351L6.62071 4.04194L6.65975 4.07072C6.69459 4.09652 6.74671 4.13536 6.81422 4.18635C6.94924 4.28834 7.14564 4.43883 7.38813 4.63059C7.87349 5.01443 8.54147 5.56206 9.27053 6.21597C9.93034 6.80777 10.6282 7.47615 11.2825 8.18057C11.0033 8.37645 10.7155 8.5656 10.4204 8.74678Z"
                            fill="currentColor"
                        />
                        <path
                            d="M16.2047 20.6626L16.2066 20.6755L16.2071 20.6791L16.2596 21.0475C13.6547 22.2776 10.7413 22.2853 8.22086 21.2588L8.28893 21.0535C8.95247 19.0517 11.0084 16.8174 13.6366 15.2182C14.0314 14.9779 14.4347 14.7547 14.8433 14.5507C15.0955 15.3805 15.314 16.2223 15.4984 17.0128C15.7377 18.0383 15.9153 18.9575 16.033 19.6201C16.0918 19.9512 16.1356 20.2176 16.1645 20.4004C16.179 20.4919 16.1898 20.5624 16.1968 20.6096L16.2047 20.6626Z"
                            fill="currentColor"
                        />
                    </g>
                </svg>
            )}
        </>
    );
};

export default IconDribbble;
