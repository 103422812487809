import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import TblWhatsAppTree from "../../Interfaces/PIMs/WhatsAppTree/TblWhatsAppTree";

interface initialWhatsAppTreeStateProps {
    WhatsAppTree : TblWhatsAppTree[]
}

const initialWhatsAppTreeState: initialWhatsAppTreeStateProps = {
    WhatsAppTree: []
};

const whatsAppTreeSlice = createSlice({
    name: "whatsAppTree",
    initialState: initialWhatsAppTreeState,
    reducers: {
      setWhatsAppTreeValue: (
        state,
        action: PayloadAction<TblWhatsAppTree>
      ) => {
          state.WhatsAppTree = [...state.WhatsAppTree, action.payload];
      },
      updateWhatsAppTreeValue: (
        state,
        action: PayloadAction<{ id: number; updatedValue: TblWhatsAppTree }>
      ) => {
        const { id, updatedValue } = action.payload;
        const index = state.WhatsAppTree.findIndex(item => item.WhatsAppTreeId === id);
        if (index !== -1) {
          state.WhatsAppTree[index] = updatedValue;
        }
      },
      deleteAllWhatsAppTreeValue: (state) => {
         state.WhatsAppTree = []
      }
    },
});

export const {
    setWhatsAppTreeValue,
    deleteAllWhatsAppTreeValue,
    updateWhatsAppTreeValue,
} = whatsAppTreeSlice.actions;

export default whatsAppTreeSlice.reducer;