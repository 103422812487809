import authApi from "./authApi";
import routeApi from "./routeApi";
import captchaApi from "./captchaApi";
import SPTblCourseApi from "./SPTblCourseApi";
import SPTblCourseDetailsApi from "./SPTblCourseDetailsApi";
import SPPackageApi from "./SPPackageApi";
import SPCommonServicesApi from "./SPCommonServicesApi";
import SPTblWhatsAppApi from "./SPTblWhatsAppApi";
import franchiseApi from "./franchiseApi";
import usersApi from "./usersApi";
import SPTblPackageApi from "./SPTblPackageApi";


export {
  authApi,
  routeApi,
  captchaApi,
  SPTblCourseApi,
  SPTblCourseDetailsApi,
  SPPackageApi,
  SPCommonServicesApi,
  SPTblWhatsAppApi,
  franchiseApi,
  usersApi,
  SPTblPackageApi,
};
