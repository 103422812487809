// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.animated-progress {
    animation: progress-bar-stripes 1s linear infinite;
}

@keyframes progress-bar-stripes {
    0% {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Assets/css/progressbar.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;AACtD;;AAEA;IACI;QACI,2BAA2B;IAC/B;;IAEA;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".animated-progress {\r\n    animation: progress-bar-stripes 1s linear infinite;\r\n}\r\n\r\n@keyframes progress-bar-stripes {\r\n    0% {\r\n        background-position: 1rem 0;\r\n    }\r\n\r\n    to {\r\n        background-position: 0 0;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
