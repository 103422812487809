import { FC } from 'react';

interface IconInboxProps {
    className?: string;
    fill?: boolean;
    duotone?: boolean;
}

const IconInbox: FC<IconInboxProps> = ({ className, fill = false, duotone = true }) => {
    return (
        <>
            {!fill ? (
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
                    <path
                        d="M3.74157 18.5545C4.94119 20 7.17389 20 11.6393 20H12.3605C16.8259 20 19.0586 20 20.2582 18.5545M3.74157 18.5545C2.54194 17.1091 2.9534 14.9146 3.77633 10.5257C4.36155 7.40452 4.65416 5.84393 5.76506 4.92196M3.74157 18.5545C3.74156 18.5545 3.74157 18.5545 3.74157 18.5545ZM20.2582 18.5545C21.4578 17.1091 21.0464 14.9146 20.2235 10.5257C19.6382 7.40452 19.3456 5.84393 18.2347 4.92196M20.2582 18.5545C20.2582 18.5545 20.2582 18.5545 20.2582 18.5545ZM18.2347 4.92196C17.1238 4 15.5361 4 12.3605 4H11.6393C8.46374 4 6.87596 4 5.76506 4.92196M18.2347 4.92196C18.2347 4.92196 18.2347 4.92196 18.2347 4.92196ZM5.76506 4.92196C5.76506 4.92196 5.76506 4.92196 5.76506 4.92196Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                    />
                    <path
                        opacity={duotone ? '0.5' : '1'}
                        d="M9.1709 8C9.58273 9.16519 10.694 10 12.0002 10C13.3064 10 14.4177 9.16519 14.8295 8"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                    />
                </svg>
            ) : (
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
                    <path
                        opacity={duotone ? '0.5' : '1'}
                        d="M5.57434 4.69147C4.74117 5.38295 4.52171 6.55339 4.0828 8.89427L3.3328 12.8943C2.7156 16.186 2.40701 17.8318 3.30672 18.9159C4.20644 20 5.88097 20 9.23003 20H14.7709C18.12 20 19.7945 20 20.6942 18.9159C21.5939 17.8318 21.2853 16.186 20.6681 12.8943L19.9181 8.89427C19.4792 6.55339 19.2598 5.38295 18.4266 4.69147C17.5934 4 16.4026 4 14.0209 4H9.98003C7.59835 4 6.40752 4 5.57434 4.69147Z"
                        fill="currentColor"
                    />
                    <path
                        d="M12.0004 9.24996C11.0219 9.24996 10.1875 8.62493 9.87823 7.75003C9.7402 7.35949 9.31171 7.1548 8.92117 7.29283C8.53063 7.43087 8.32594 7.85936 8.46397 8.24989C8.97841 9.70537 10.3665 10.75 12.0004 10.75C13.6343 10.75 15.0224 9.70537 15.5368 8.24989C15.6749 7.85936 15.4702 7.43087 15.0796 7.29283C14.6891 7.1548 14.2606 7.35949 14.1226 7.75003C13.8133 8.62493 12.9789 9.24996 12.0004 9.24996Z"
                        fill="currentColor"
                    />
                </svg>
            )}
        </>
    );
};

export default IconInbox;
