import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FranchiseInsertDTO, FranchiseUpdateDTO } from "../Interfaces/DTOs";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_APIBaseUrl}/users`,
});

const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: async (args, api, extraOptions) => {
    try {
      const result = await baseQuery(args, api, extraOptions);
      return result;
    } catch (error: any) {
      if (error.status === 401) {
        localStorage.removeItem("token");
        window.location.replace("/auth/login");
      }
      throw error;
    }
  },
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => ({
        url: "",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    }),
    getUsersByFranchise: builder.query({
      query: () => ({
        url: "byFranchise",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const { useGetAllUsersQuery, useGetUsersByFranchiseQuery } = usersApi;
export default usersApi;
