import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SD_SPNameList } from "../Utility/StaticDatas";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_APIBaseUrl}/AuthDataGet/ExecuteJson/${SD_SPNameList.DB_SP_CommonServices}`,
});

const SPCommonServicesApi = createApi({
  reducerPath: "SPCommonServicesApi",
  baseQuery: async (args, api, extraOptions) => {
    try {
      const result = await baseQuery(args, api, extraOptions);
      return result;
    } catch (error: any) {
      if (error.status === 401) {
        localStorage.removeItem("token");
        window.location.replace("/auth/login");
      }
      throw error;
    }
  },
  endpoints: (builder) => ({
    getAllTermList: builder.query({
      query: () => ({
        url: "1",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: {},
      }),
    }),
    getAllInternalServiceTypeList: builder.query({
      query: () => ({
        url: "2",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: {},
      }),
    }),
    getAllCourseList: builder.query({
      query: () => ({
        url: "3",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: {},
      }),
    }),
    getAllServiceCourseDetailTypeList: builder.query({
      query: () => ({
        url: "4",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: {},
      }),
    }),
  }),
});

export const {
  useGetAllTermListQuery,
  useGetAllInternalServiceTypeListQuery,
  useGetAllCourseListQuery,
  useGetAllServiceCourseDetailTypeListQuery,
} = SPCommonServicesApi;
export default SPCommonServicesApi;
