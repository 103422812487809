import React, { useEffect, useState } from "react";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { downloadExcel } from "react-export-table-to-excel";
import { IconEye, IconFile, IconPencil, IconPrinter, IconTrash } from "../Icon";
import { DataTableProps } from "../../Interfaces/Props";
import sortBy from "lodash/sortBy";
import { Group, ActionIcon } from "@mantine/core";

interface RecordType {
  [key: string]: any;
}

function MainDataTable(props: DataTableProps) {
  const PAGE_SIZES = [20, 30, 50, 100];

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [initialRecords, setInitialRecords] = useState([]);
  const [recordsData, setRecordsData] = useState(initialRecords);
  const [search, setSearch] = useState("");
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>(
    props.order
  );

  useEffect(() => {
    if (!props.fetching) {
      setInitialRecords(props.rowData);
    }
  }, [props.rowData, props.fetching]);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecordsData([...initialRecords.slice(from, to)]);
  }, [page, pageSize, initialRecords, props.rowData]);

  useEffect(() => {
    setInitialRecords(() => {
      return props.rowData.filter((item: any) => {
        return Object.keys(item).some(
          (key) =>
            // Check if item[key] is a string before calling toLowerCase
            typeof item[key] === "string" &&
            item[key].toLowerCase().includes(search.toLowerCase())
        );
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const data = sortBy(initialRecords, sortStatus.columnAccessor);
    setInitialRecords(sortStatus.direction === "desc" ? data.reverse() : data);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortStatus]);

  const col = props.columns.map((obj) => obj.accessor);

  const header = props.columns.map((obj) => obj.title);

  function handleDownloadExcel() {
    downloadExcel({
      fileName: "table",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        body: props.rowData,
      },
    });
  }

  const capitalize = (text: any) => {
    return text
      .replace("_", " ")
      .replace("-", " ")
      .toLowerCase()
      .split(" ")
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
  };

  const exportTable = (type: any) => {
    let columns: any = col;
    let records = props.rowData;
    let filename = props.exportName;

    let newVariable: any;
    newVariable = window.navigator;

    if (type === "csv") {
      let coldelimiter = ";";
      let linedelimiter = "\n";
      let result = columns
        .map((d: any) => {
          return capitalize(d);
        })
        .join(coldelimiter);
      result += linedelimiter;
      // eslint-disable-next-line array-callback-return
      records.map((item: any) => {
        // eslint-disable-next-line array-callback-return
        columns.map((d: any, index: any) => {
          if (index > 0) {
            result += coldelimiter;
          }
          let val = item[d] ? item[d] : "";
          result += val;
        });
        result += linedelimiter;
      });

      if (result == null) return;
      if (!result.match(/^data:text\/csv/i) && !newVariable.msSaveOrOpenBlob) {
        var data =
          "data:application/csv;charset=utf-8," + encodeURIComponent(result);
        var link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", filename + ".csv");
        link.click();
      } else {
        var blob = new Blob([result]);
        if (newVariable.msSaveOrOpenBlob) {
          newVariable.msSaveBlob(blob, filename + ".csv");
        }
      }
    } else if (type === "print") {
      var rowhtml = "<p>" + filename + "</p>";
      rowhtml +=
        '<table style="width: 100%; " cellpadding="0" cellcpacing="0"><thead><tr style="color: #515365; background: #eff5ff; -webkit-print-color-adjust: exact; print-color-adjust: exact; "> ';
      // eslint-disable-next-line array-callback-return
      columns.map((d: any) => {
        rowhtml += "<th>" + capitalize(d) + "</th>";
      });
      rowhtml += "</tr></thead>";
      rowhtml += "<tbody>";

      // eslint-disable-next-line array-callback-return
      records.map((item: any) => {
        rowhtml += "<tr>";
        // eslint-disable-next-line array-callback-return
        columns.map((d: any) => {
          let val = item[d] ? item[d] : "";
          rowhtml += "<td>" + val + "</td>";
        });
        rowhtml += "</tr>";
      });
      rowhtml +=
        "<style>body {font-family:Arial; color:#495057;}p{text-align:center;font-size:18px;font-weight:bold;margin:15px;}table{ border-collapse: collapse; border-spacing: 0; }th,td{font-size:12px;text-align:left;padding: 4px;}th{padding:8px 4px;}tr:nth-child(2n-1){background:#f7f7f7; }</style>";
      rowhtml += "</tbody></table>";
      var winPrint: any = window.open(
        "",
        "",
        "left=0,top=0,width=1000,height=600,toolbar=0,scrollbars=0,status=0"
      );
      winPrint.document.write("<title>Print</title>" + rowhtml);
      winPrint.document.close();
      winPrint.focus();
      winPrint.print();
    } else if (type === "txt") {
      let coldelimiter = ",";
      let linedelimiter = "\n";
      let result = columns
        .map((d: any) => {
          return capitalize(d);
        })
        .join(coldelimiter);
      result += linedelimiter;
      // eslint-disable-next-line array-callback-return
      records.map((item: any) => {
        // eslint-disable-next-line array-callback-return
        columns.map((d: any, index: any) => {
          if (index > 0) {
            result += coldelimiter;
          }
          let val = item[d] ? item[d] : "";
          result += val;
        });
        result += linedelimiter;
      });

      if (result == null) return;
      if (!result.match(/^data:text\/txt/i) && !newVariable.msSaveOrOpenBlob) {
        var data1 =
          "data:application/txt;charset=utf-8," + encodeURIComponent(result);
        var link1 = document.createElement("a");
        link1.setAttribute("href", data1);
        link1.setAttribute("download", filename + ".txt");
        link1.click();
      } else {
        var blob1 = new Blob([result]);
        if (newVariable.msSaveOrOpenBlob) {
          newVariable.msSaveBlob(blob1, filename + ".txt");
        }
      }
    }
  };

  const conditionalColumns = [
    props.isToggleBtn && {
      accessor: "toggle",
      title: "Status",
      textAlign: "center",
      render: (record: RecordType) =>
        props.activeCoumnCreate && props.activeCoumnCreate(record),
    },
    (props.isDeleteBtn || props.isEditBtn || props.isViewBtn) && {
      accessor: "actions",
      title: "Actions",
      textAlign: "center",
      render: (record: RecordType) => (
        <Group gap={8} justify='center' wrap='nowrap'>
          {props.isViewBtn && (
            <ActionIcon
              size='sm'
              variant='subtle'
              color='red'
              onClick={(e) => {
                e.stopPropagation();
                props.handleView?.(record);
              }}>
              <IconEye className='text-green-600' />
            </ActionIcon>
          )}
          {props.isEditBtn && (
            <ActionIcon
              size='sm'
              variant='subtle'
              color='blue'
              onClick={(e) => {
                e.stopPropagation();
                props.handleEdit?.(record);
              }}>
              <IconPencil className='text-yellow-600' />
            </ActionIcon>
          )}
          {props.isDeleteBtn && (
            <ActionIcon
              size='sm'
              variant='subtle'
              color='red'
              onClick={(e) => {
                e.stopPropagation();
                props.handleDelete?.(record);
              }}>
              <IconTrash className='text-red-600' />
            </ActionIcon>
          )}
        </Group>
      ),
    },
  ].filter(Boolean);

  return (
    <>
      <div className='panel mt-6'>
        <div className='flex md:items-center justify-between md:flex-row flex-col mb-4.5 gap-5'>
          <div className='flex items-center flex-wrap'>
            {props.isExportCSV && (
              <button
                type='button'
                onClick={() => exportTable("csv")}
                className='btn btn-primary btn-sm m-1 '>
                <IconFile className='w-5 h-5 ltr:mr-2 rtl:ml-2' />
                CSV
              </button>
            )}
            {props.isExportTXT && (
              <button
                type='button'
                onClick={() => exportTable("txt")}
                className='btn btn-primary btn-sm m-1'>
                <IconFile className='w-5 h-5 ltr:mr-2 rtl:ml-2' />
                TXT
              </button>
            )}
            {props.isExportExcel && (
              <button
                type='button'
                className='btn btn-primary btn-sm m-1'
                onClick={handleDownloadExcel}>
                <IconFile className='w-5 h-5 ltr:mr-2 rtl:ml-2' />
                {props.excelBtnName ? props.excelBtnName : "EXCEL"}
              </button>
            )}
            {props.isExportPRINT && (
              <button
                type='button'
                onClick={() => exportTable("print")}
                className='btn btn-primary btn-sm m-1'>
                <IconPrinter className='ltr:mr-2 rtl:ml-2' />
                PRINT
              </button>
            )}
          </div>

          <input
            type='text'
            className='form-input w-auto'
            placeholder='Search...'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className='datatables'>
          <DataTable
            highlightOnHover
            striped
            className='table-striped table-hover table-bordered table-compact'
            records={recordsData}
            columns={[...props.columns, ...conditionalColumns]}
            totalRecords={initialRecords.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={setSortStatus}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) =>
              `Showing  ${from} to ${to} of ${totalRecords} entries`
            }
            paginationSize='lg'
            loaderType='dots'
            loaderSize='xl'
            loaderColor='grape'
            loaderBackgroundBlur={6}
            pinLastColumn={props.pinLastColumn}
            fetching={props.fetching}
            height={props.height}
          />
        </div>
      </div>
    </>
  );
}

export default MainDataTable;
