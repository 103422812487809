import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SD_SPNameList } from "../Utility/StaticDatas";
import { CourseDetailModel } from "../Interfaces/PIMs";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_APIBaseUrl}/AuthDataGet/ExecuteJson/${SD_SPNameList.DB_SP_CourseDetails}`,
});

const SPTblCourseDetailsApi = createApi({
  reducerPath: "spTblCourseDetailsApi",
  baseQuery: async (args, api, extraOptions) => {
    try {
      const result = await baseQuery(args, api, extraOptions);
      return result;
    } catch (error: any) {
      if (error.status === 401) {
        localStorage.removeItem("token");
        window.location.replace("/auth/login");
      }
      throw error;
    }
  },
  tagTypes: ["CourseDetail"],
  endpoints: (builder) => ({
    insertCourseDetail: builder.mutation({
      query: (data: CourseDetailModel) => ({
        url: "1",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["CourseDetail"],
    }),
    updateCourseDetail: builder.mutation({
      query: (data: CourseDetailModel) => ({
        url: "2",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["CourseDetail"],
    }),
    deleteCourseDetail: builder.mutation({
      query: (data: CourseDetailModel) => ({
        url: "3",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["CourseDetail"],
    }),
    getCourseDetailById: builder.query({
      query: (data: CourseDetailModel) => ({
        url: "4",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      providesTags: ["CourseDetail"],
    }),
    toggleActivationCourseDetail: builder.mutation({
      query: (data: CourseDetailModel) => ({
        url: "5",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["CourseDetail"],
    }),
    getAllCourseDetailByCourseId: builder.query({
      query: (data: CourseDetailModel) => ({
        url: "6",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      providesTags: ["CourseDetail"],
    }),
  }),
});

export const {
  useInsertCourseDetailMutation,
  useUpdateCourseDetailMutation,
  useDeleteCourseDetailMutation,
  useGetCourseDetailByIdQuery,
  useToggleActivationCourseDetailMutation,
  useGetAllCourseDetailByCourseIdQuery,
} = SPTblCourseDetailsApi;
export default SPTblCourseDetailsApi;
