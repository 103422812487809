export enum SD_InputStruc {
  inputText = "inputText",
  inputNumber = "inputNumber",
  inputBoolean = "inputBoolean",
  inputArray = "inputArray",
  inputSelect = "inputSelect",
}

export enum SD_SPNameList {
  DB_SP_Term = "sptblterm",
  DB_SP_InternalServicesType = "spInternalServicesType",
  DB_SP_Course = "sptblCourse",
  DB_SP_CourseDetails = "sptblCourseDetails",
  DB_SP_Package = "sptblPackage",
  DB_SP_PackageDetails = "sptblPackageDetails",
  DB_SP_CommonServices = "spCommonServices",
  DB_SP_WhatsApp = "sptblWhatsApp",
  DB_SP_WhatsAppDetails = "sptblWhatsAppDetails",
  DB_SP_InternelWhatsAppResponse = "spTblInternelWhatsAppResponse",
  DB_SP_WhatsAppTree = "sptblWhatsAppTree",
}

export enum SD_Category {
  Structure = "Structure",
  Attribute = "Attribute",
  Pricing = "Pricing",
  Details = "Details",
}

export enum SD_DynamicFormElement {
  PDF = "pdf",
  IMAGE = "image",
  LINK = "link",
  PLAIN_TEXT = "plain_text",
  FORMATTED_TEXT = "formatted_text",
  VIDEO_URL = "video_url",
  ZIP_RAR = "zip_rar",
  OTHERS = "others",
}
