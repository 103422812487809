import React, { useEffect, useState } from "react";
import { withAuth } from "../HOC";
import { useDispatch, useSelector } from "react-redux";
import { setPageTitle } from "../Storage/Redux/themeConfigSlice";
import { IRootState } from "../Storage/Redux/store";
import axios from "axios";

function Dashboard() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTitle("DashBoard Admin"));
  });
  const isDark = useSelector(
    (state: IRootState) =>
      state.themeConfig.theme === "dark" || state.themeConfig.isDarkMode
  );
  const isRtl =
    useSelector((state: IRootState) => state.themeConfig.rtlClass) === "rtl"
      ? true
      : false;

  const [loading] = useState(false);

  // useEffect(() => {
  //   const routeData = {
  //     routeName: "Dashboard",
  //     routePath: "/",
  //     routeExact: true,
  //     routeComponent: "Dashboard",
  //   };

  //   const saveRouteData = async () => {
  //     try {
  //       await axios.post('http://dthclass.com/api/router', routeData);
  //       
  //     } catch (error) {
  //       console.error('Error saving routeData:', error);
  //     }
  //   };

  //   saveRouteData();
  // }, []);

  return <div>Dashboard</div>;
}

export default withAuth(Dashboard);
