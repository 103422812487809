import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FranchiseInsertDTO, FranchiseUpdateDTO } from "../Interfaces/DTOs";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_APIBaseUrl}/Franchise`,
});

const franchiseApi = createApi({
  reducerPath: "franchiseApi",
  baseQuery: async (args, api, extraOptions) => {
    try {
      const result = await baseQuery(args, api, extraOptions);
      return result;
    } catch (error: any) {
      if (error.status === 401) {
        localStorage.removeItem("token");
        window.location.replace("/auth/login");
      }
      throw error;
    }
  },
  tagTypes: ["Franchise"],
  endpoints: (builder) => ({
    getAllFranchise: builder.query({
      query: () => ({
        url: "",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
      providesTags: ["Franchise"],
    }),
    getFranchiseById: builder.query({
      query: ({ id }) => ({
        url: `${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
      providesTags: ["Franchise"],
    }),
    postFranchise: builder.mutation({
      query: ({ key, obj }: { key: string; obj: FranchiseInsertDTO }) => ({
        url: `${key}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: obj,
      }),
      invalidatesTags: ["Franchise"],
    }),
    putFranchise: builder.mutation({
      query: (obj: FranchiseUpdateDTO) => ({
        url: `${obj.franchiseId}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: obj,
      }),
      invalidatesTags: ["Franchise"],
    }),
    toggleActivationFranchise: builder.mutation({
      query: (id) => ({
        url: `FranchiseActivation/${id}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["Franchise"],
    }),
    toggleActivationFranchiseCloud: builder.mutation({
      query: (id) => ({
        url: `CloudDetailActivation/${id}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["Franchise"],
    }),
    deleteFranchise: builder.mutation({
      query: (id) => ({
        url: `FranchiseDeletion/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["Franchise"],
    }),
    deleteFranchiseCloud: builder.mutation({
      query: (id) => ({
        url: `CloudDetailDeletion/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["Franchise"],
    }),
  }),
});

export const {
  useGetAllFranchiseQuery,
  useGetFranchiseByIdQuery,
  usePostFranchiseMutation,
  usePutFranchiseMutation,
  useToggleActivationFranchiseMutation,
  useToggleActivationFranchiseCloudMutation,
  useDeleteFranchiseMutation,
  useDeleteFranchiseCloudMutation,
} = franchiseApi;
export default franchiseApi;
