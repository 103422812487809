// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper .swiper-button-disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.rtl .swiper {
    direction: rtl;
}

.swiper-button-next {
    color: #4361ee;
}

#slider3 .swiper-wrapper,
#slider4 .swiper-wrapper {
    height: 320px !important;
}

#slider3 .swiper-wrapper .swiper-slide img,
#slider4 .swiper-wrapper .swiper-slide img {
    height: 100%;
    object-fit: cover;
}

#slider3 .swiper-pagination .swiper-pagination-bullet {
    border-radius: 6px;
    width: 4px;
    height: 20px;
}

@media only screen and (max-width: 1200px) {
    #slider3 .swiper-wrapper,
    #slider4 .swiper-wrapper {
        height: 300px !important;
    }
}

@media only screen and (max-width: 600px) {
    #slider3 .swiper-wrapper,
    #slider4 .swiper-wrapper {
        height: 180px !important;
    }
}

#slider3 .swiper-pagination .swiper-pagination-bullet:hover {
    background-color: #fff;
}

#slider3 .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #4361ee;
}

#slider4 .swiper-pagination {
    color: #fff;
}
#slider5 .swiper-wrapper {
    height: auto;
}
#slider5 .swiper-pagination {
    position: relative;
    margin-top: 20px;
}


.paddintTop_0{
    padding-top: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/Assets/css/swiper.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;;IAEI,wBAAwB;AAC5B;;AAEA;;IAEI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;AAChB;;AAEA;IACI;;QAEI,wBAAwB;IAC5B;AACJ;;AAEA;IACI;;QAEI,wBAAwB;IAC5B;AACJ;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;AACA;IACI,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;;AAGA;IACI,yBAAyB;AAC7B","sourcesContent":[".swiper .swiper-button-disabled {\r\n    cursor: not-allowed;\r\n    opacity: 0.6;\r\n}\r\n\r\n.rtl .swiper {\r\n    direction: rtl;\r\n}\r\n\r\n.swiper-button-next {\r\n    color: #4361ee;\r\n}\r\n\r\n#slider3 .swiper-wrapper,\r\n#slider4 .swiper-wrapper {\r\n    height: 320px !important;\r\n}\r\n\r\n#slider3 .swiper-wrapper .swiper-slide img,\r\n#slider4 .swiper-wrapper .swiper-slide img {\r\n    height: 100%;\r\n    object-fit: cover;\r\n}\r\n\r\n#slider3 .swiper-pagination .swiper-pagination-bullet {\r\n    border-radius: 6px;\r\n    width: 4px;\r\n    height: 20px;\r\n}\r\n\r\n@media only screen and (max-width: 1200px) {\r\n    #slider3 .swiper-wrapper,\r\n    #slider4 .swiper-wrapper {\r\n        height: 300px !important;\r\n    }\r\n}\r\n\r\n@media only screen and (max-width: 600px) {\r\n    #slider3 .swiper-wrapper,\r\n    #slider4 .swiper-wrapper {\r\n        height: 180px !important;\r\n    }\r\n}\r\n\r\n#slider3 .swiper-pagination .swiper-pagination-bullet:hover {\r\n    background-color: #fff;\r\n}\r\n\r\n#slider3 .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {\r\n    background: #4361ee;\r\n}\r\n\r\n#slider4 .swiper-pagination {\r\n    color: #fff;\r\n}\r\n#slider5 .swiper-wrapper {\r\n    height: auto;\r\n}\r\n#slider5 .swiper-pagination {\r\n    position: relative;\r\n    margin-top: 20px;\r\n}\r\n\r\n\r\n.paddintTop_0{\r\n    padding-top: 0 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
