import React, { useState, useEffect, ChangeEvent } from 'react';

type ImageUploadPreviewProps = {
  initialImageUrl?: string;
  onImageChange: (file: File | null) => void;
  errorText?: string;
  labelName: string;
  showImagePreview?: boolean
};

const ImageUploadPreview: React.FC<ImageUploadPreviewProps> = ({
  initialImageUrl = '',
  onImageChange,
  errorText,
  labelName,
  showImagePreview = false
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string>(initialImageUrl);

  useEffect(() => {
    return () => {
      if (previewUrl) URL.revokeObjectURL(previewUrl);
    };
  }, [previewUrl]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      onImageChange(file);
    } else {
      onImageChange(null);
    }
  };

  return (
    <div>
      <label>{labelName}</label>
      <input
        type="file"
        className="form-input"
        onChange={handleFileChange}
      />
      {showImagePreview && previewUrl && (
        <img src={previewUrl} alt="Image Preview" className="w-[60px] h-[60px] mt-3 ml-5" />
      )}
      {errorText && <p className="text-red-500 text-xs italic">{errorText}</p>}
    </div>
  );
};

export default ImageUploadPreview;
