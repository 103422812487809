// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tippy-box[data-theme~='primary'] {
    background-color: #4361ee;
}

.tippy-box[data-theme~='primary'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: #4361ee;
}

.tippy-box[data-theme~='success'] {
    background-color: #00ab55;
}

.tippy-box[data-theme~='success'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: #00ab55;
}

.tippy-box[data-theme~='info'] {
    background-color: #2196f3;
}

.tippy-box[data-theme~='info'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: #2196f3;
}

.tippy-box[data-theme~='danger'] {
    background-color: #e7515a;
}

.tippy-box[data-theme~='danger'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: #e7515a;
}

.tippy-box[data-theme~='warning'] {
    background-color: #e2a03f;
}

.tippy-box[data-theme~='warning'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: #e2a03f;
}

.tippy-box[data-theme~='secondary'] {
    background-color: #805dca;
}

.tippy-box[data-theme~='secondary'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: #805dca;
}

.tippy-box[data-theme~='dark'] {
    background-color: #3b3f5c;
}

.tippy-box[data-theme~='dark'][data-placement^='top'] > .tippy-arrow::before {
    border-top-color: #3b3f5c;
}
`, "",{"version":3,"sources":["webpack://./src/Assets/css/tippy.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".tippy-box[data-theme~='primary'] {\r\n    background-color: #4361ee;\r\n}\r\n\r\n.tippy-box[data-theme~='primary'][data-placement^='top'] > .tippy-arrow::before {\r\n    border-top-color: #4361ee;\r\n}\r\n\r\n.tippy-box[data-theme~='success'] {\r\n    background-color: #00ab55;\r\n}\r\n\r\n.tippy-box[data-theme~='success'][data-placement^='top'] > .tippy-arrow::before {\r\n    border-top-color: #00ab55;\r\n}\r\n\r\n.tippy-box[data-theme~='info'] {\r\n    background-color: #2196f3;\r\n}\r\n\r\n.tippy-box[data-theme~='info'][data-placement^='top'] > .tippy-arrow::before {\r\n    border-top-color: #2196f3;\r\n}\r\n\r\n.tippy-box[data-theme~='danger'] {\r\n    background-color: #e7515a;\r\n}\r\n\r\n.tippy-box[data-theme~='danger'][data-placement^='top'] > .tippy-arrow::before {\r\n    border-top-color: #e7515a;\r\n}\r\n\r\n.tippy-box[data-theme~='warning'] {\r\n    background-color: #e2a03f;\r\n}\r\n\r\n.tippy-box[data-theme~='warning'][data-placement^='top'] > .tippy-arrow::before {\r\n    border-top-color: #e2a03f;\r\n}\r\n\r\n.tippy-box[data-theme~='secondary'] {\r\n    background-color: #805dca;\r\n}\r\n\r\n.tippy-box[data-theme~='secondary'][data-placement^='top'] > .tippy-arrow::before {\r\n    border-top-color: #805dca;\r\n}\r\n\r\n.tippy-box[data-theme~='dark'] {\r\n    background-color: #3b3f5c;\r\n}\r\n\r\n.tippy-box[data-theme~='dark'][data-placement^='top'] > .tippy-arrow::before {\r\n    border-top-color: #3b3f5c;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
