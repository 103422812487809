import { combineReducers, configureStore } from "@reduxjs/toolkit";
import themeConfigSlice from "./themeConfigSlice";
import {
  SPTblCourseApi,
  SPTblCourseDetailsApi,
  authApi,
  captchaApi,
  routeApi,
  SPPackageApi,
  SPCommonServicesApi,
  SPTblWhatsAppApi,
  franchiseApi,
  usersApi,
  SPTblPackageApi,
} from "../../Apis";
import { userAuthReducer } from "./userAuthSlice";
import createCourseSlice from "./createCourseSlice";
import createPackageSlice from "./createPackageSlice";
import createWhatsAppSlice from "./createWhatsAppSlice";
import SPTblWhatsAppDetailsApi from "../../Apis/SPTblWhatsAppDetailsApi";
import { routesReducer } from "./routesSlice";
import SPTblPackageDetailsApi from "../../Apis/SPTblPackageDetailsApi";
import SPWhatsAppTreeApi from "../../Apis/SPWhatsAppTreeApi";
import whatsAppTreeSlice from "./whatsAppTreeSlice";
import { resetUserReducer } from "./resetUserSlice";

const rootReducer = combineReducers({
  themeConfig: themeConfigSlice,
  userAuthStore: userAuthReducer,
  routesStore: routesReducer,
  restUserStore: resetUserReducer,
  course: createCourseSlice,
  whatsAppTree: whatsAppTreeSlice,
  whatsApp: createWhatsAppSlice,
  package: createPackageSlice,
  [authApi.reducerPath]: authApi.reducer,
  [routeApi.reducerPath]: routeApi.reducer,
  [captchaApi.reducerPath]: captchaApi.reducer,
  [SPPackageApi.reducerPath]: SPPackageApi.reducer,
  [SPTblPackageDetailsApi.reducerPath]: SPTblPackageDetailsApi.reducer,
  [SPTblCourseApi.reducerPath]: SPTblCourseApi.reducer,
  [SPTblWhatsAppApi.reducerPath]: SPTblWhatsAppApi.reducer,
  [SPWhatsAppTreeApi.reducerPath]: SPWhatsAppTreeApi.reducer,
  [SPTblCourseDetailsApi.reducerPath]: SPTblCourseDetailsApi.reducer,
  [SPTblPackageApi.reducerPath]: SPTblPackageApi.reducer,
  [SPTblWhatsAppDetailsApi.reducerPath]: SPTblWhatsAppDetailsApi.reducer,
  [SPCommonServicesApi.reducerPath]: SPCommonServicesApi.reducer,
  [franchiseApi.reducerPath]: franchiseApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
});

const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(routeApi.middleware)
      .concat(captchaApi.middleware)
      .concat(SPTblCourseApi.middleware)
      .concat(SPTblWhatsAppApi.middleware)
      .concat(SPWhatsAppTreeApi.middleware)
      .concat(SPPackageApi.middleware)
      .concat(SPTblCourseDetailsApi.middleware)
      .concat(SPTblPackageApi.middleware)
      .concat(SPTblPackageDetailsApi.middleware)
      .concat(SPTblWhatsAppDetailsApi.middleware)
      .concat(SPCommonServicesApi.middleware)
      .concat(SPCommonServicesApi.middleware)
      .concat(franchiseApi.middleware)
      .concat(usersApi.middleware),
});

export type IRootState = ReturnType<typeof rootReducer>;

export default store;
