import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { string } from "yup";
import { ResetPasswordDTO } from "../Interfaces/DTOs";

const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_APIBaseUrl}/auth`,
  }),
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (userData) => ({
        url: "register",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: userData,
      }),
    }),
    loginUser: builder.mutation({
      query: (userCredentials) => ({
        url: "login",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: userCredentials,
      }),
    }),
    resetPassword: builder.mutation({
      query: ({
        code,
        userDetail,
      }: {
        code: string;
        userDetail: ResetPasswordDTO;
      }) => ({
        url: `resetPassword/${code}`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: userDetail,
      }),
    }),
    authenticateUser: builder.mutation({
      query: () => ({
        url: "authentication",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
      }),
    }),
    getCode: builder.mutation({
      query: (obj) => ({
        url: "generateCode",
        method: "POST",
        body: obj,
      }),
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation,
  useResetPasswordMutation,
  useAuthenticateUserMutation,
  useGetCodeMutation,
} = authApi;
export default authApi;
