import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SD_SPNameList } from "../Utility/StaticDatas";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_APIBaseUrl}/AuthDataGet/ExecuteJson/${SD_SPNameList.DB_SP_Package}`,
});
const SPPackageApi = createApi({
  reducerPath: "spTblPackage",
  baseQuery: async (args, api, extraOptions) => {
    try {
      const result = await baseQuery(args, api, extraOptions);
      return result;
    } catch (error: any) {
      if (error.status === 401) {
        localStorage.removeItem("token");
        window.location.replace("/auth/login");
      }
      throw error;
    }
  },
  tagTypes: ["Packages"],
  endpoints: (builder) => ({
    getAllCourse: builder.query({
      query: () => ({
        url: "7",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: {},
      }),
       providesTags: ["Packages"],
    }),
  }),
});

export const {
  useGetAllCourseQuery,
} = SPPackageApi;

export default SPPackageApi;