import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import PackageDetail from "../../Interfaces/PIMs/PackageValue/PackageDetail";

const initialCourseState: PackageDetail = {
  CourseId: "",
  PackageName: "",
  PackageDisplayName: "",
  SrNo: "",
  TermId: "",
  PackageId: "",
};

const packageSlice = createSlice({
  name: "package",
  initialState: initialCourseState,
  reducers: {
    setPackage: (
      state,
      action: PayloadAction<PackageDetail>
    ) => {
      console.log("action",action);
      state.CourseId = action.payload.CourseId;
      state.PackageName = action.payload.PackageName;
      state.PackageDisplayName = action.payload.PackageDisplayName;
      state.SrNo = action.payload.SrNo;
      state.TermId = action.payload.TermId;
      state.PackageId = action.payload.PackageId
    },
  },
});

export const {
  setPackage,
} = packageSlice.actions;

export default packageSlice.reducer;
