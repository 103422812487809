import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const captchaApi = createApi({
  reducerPath: "captchaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_APIBaseUrl}/captcha`,
  }),
  endpoints: (builder) => ({
    getCaptcha: builder.query({
      query: () => ({
        url: "generate",
      }),
    }),
  }),
});

export const { useGetCaptchaQuery } = captchaApi;
export default captchaApi;
