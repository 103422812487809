import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SD_SPNameList } from "../Utility/StaticDatas";
import { CourseMasterPIM } from "../Interfaces/PIMs";
import WhatsAppMasterPIM from "../Interfaces/PIMs/WhatsAppMasterPIM";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_APIBaseUrl}/AuthDataGet/ExecuteJson/${SD_SPNameList.DB_SP_WhatsApp}`,
});

const SPTblWhatsAppApi = createApi({
  reducerPath: "spTblWhatsApp",
  baseQuery: async (args, api, extraOptions) => {
    try {
      const result = await baseQuery(args, api, extraOptions);
      return result;
    } catch (error: any) {
      if (error.status === 401) {
        localStorage.removeItem("token");
        window.location.replace("/auth/login");
      }
      throw error;
    }
  },
  tagTypes: ["WhatsApp"],
  endpoints: (builder) => ({
    insertWhatsApp: builder.mutation({
      query: (data: any) => ({
        url: "1",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["WhatsApp"],
    }),
    updateWhatsApp: builder.mutation({
      query: (data: WhatsAppMasterPIM) => ({
        url: "2",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["WhatsApp"],
    }),
    getAllWhatsApp: builder.query({
      query: () => ({
        url: "4",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: {},
      }),
      providesTags: ["WhatsApp"],
    }),
    getWhatsAppById: builder.query({
      query: (data: WhatsAppMasterPIM) => ({
        url: "4",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      providesTags: ["WhatsApp"],
    }),
    deleteWhatsApp: builder.mutation({
      query: (data: WhatsAppMasterPIM) => ({
        url: "3",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["WhatsApp"],
    }),
    toggleActivationWhatsApp: builder.mutation({
      query: (data: WhatsAppMasterPIM) => ({
        url: "5",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["WhatsApp"],
    }),
  }),
});

export const {useUpdateWhatsAppMutation, useInsertWhatsAppMutation, useGetAllWhatsAppQuery, useGetWhatsAppByIdQuery, useToggleActivationWhatsAppMutation, useDeleteWhatsAppMutation } = SPTblWhatsAppApi

export default SPTblWhatsAppApi;