// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.abc{
    text-align: center;
}

input[type="text"]:focus ~ .input-text{
    --tw-translate-y: -2rem;
    --tw-translate-x: -1rem;
    --tw-scale-x: .75;
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    border-radius: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(30 64 175 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.DropZone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 80%;
    border: 4px dashed rgb(0, 82, 235);
}


input[type="text"]:focus ~ .input-text{
    --tw-translate-y: -6rem;
    --tw-translate-x: -1rem;
    --tw-scale-x: .75;
    --tw-scale-y: .75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    border-radius: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(30 64 175 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}

.DropZone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 80%;
    border: 4px dashed rgb(0, 82, 235);
}

.rti--input {
    border-radius: 0.375rem;
    width: 100% !important;
  }

  .rti--container {
    padding: 15px;
  }
  `, "",{"version":3,"sources":["webpack://./src/Assets/css/styleG.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAGI;IAAA,uBAAyF;IAAzF,uBAAyF;IAAzF,iBAAyF;IAAzF,iBAAyF;IAAzF,+LAAyF;IAAzF,qBAAyF;IAAzF,kBAAyF;IAAzF,uDAAyF;IAAzF,oBAAyF;IAAzF;AAAyF;;AAG7F;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,UAAU;IACV,kCAAkC;AACtC;;;AAII;IAAA,uBAA0F;IAA1F,uBAA0F;IAA1F,iBAA0F;IAA1F,iBAA0F;IAA1F,+LAA0F;IAA1F,qBAA0F;IAA1F,kBAA0F;IAA1F,uDAA0F;IAA1F,oBAA0F;IAA1F;AAA0F;;AAG9F;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,UAAU;IACV,kCAAkC;AACtC;;AAEA;IACI,uBAAuB;IACvB,sBAAsB;EACxB;;EAEA;IACE,aAAa;EACf","sourcesContent":[".abc{\r\n    text-align: center;\r\n}\r\n\r\ninput[type=\"text\"]:focus ~ .input-text{\r\n    @apply text-white rounded-lg bg-blue-800 transform -translate-y-8 -translate-x-4 scale-75;\r\n}\r\n\r\n.DropZone{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 150px;\r\n    width: 80%;\r\n    border: 4px dashed rgb(0, 82, 235);\r\n}\r\n\r\n\r\ninput[type=\"text\"]:focus ~ .input-text{\r\n    @apply text-white rounded-lg bg-blue-800 transform -translate-y-24 -translate-x-4 scale-75;\r\n}\r\n\r\n.DropZone{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 150px;\r\n    width: 80%;\r\n    border: 4px dashed rgb(0, 82, 235);\r\n}\r\n\r\n.rti--input {\r\n    border-radius: 0.375rem;\r\n    width: 100% !important;\r\n  }\r\n\r\n  .rti--container {\r\n    padding: 15px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
