import { createSlice } from "@reduxjs/toolkit";
import { userModel } from "../../Interfaces";

export const emptyUserState: userModel = {
  FirstName: "",
  FranchiseId: "",
  LastName: "",
  email: "",
  Mobile: "",
  nameid: "",
  exp: 0,
  role: "",
};

export const userAuthSlice = createSlice({
  name: "userAuth",
  initialState: emptyUserState,
  reducers: {
    setLoggedInUser: (state, action) => {
      state.FirstName = action.payload.FirstName;
      state.FranchiseId = action.payload.FranchiseId;
      state.LastName = action.payload.LastName;
      state.email = action.payload.email;
      state.Mobile = action.payload.Mobile;
      state.nameid = action.payload.nameid;
      state.exp = action.payload.exp;
      state.role = action.payload.role;
    },
  },
});

export const { setLoggedInUser } = userAuthSlice.actions;
export const userAuthReducer = userAuthSlice.reducer;
