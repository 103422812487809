import { createSlice } from "@reduxjs/toolkit";
import { TblRouteStore } from "../../Interfaces/DataBase";

export const emptyRouteState: TblRouteStore[] = [
  {
    routeId: -1,
    routeName: "",
    routePath: "",
    routeIcon: "",
    parentRouteId: -1,
    sortOrder: 0,
  },
];

export const routesSlice = createSlice({
  name: "routes",
  initialState: emptyRouteState,
  reducers: {
    setRoutes: (state, action) => {
      return action.payload
        .filter((obj: TblRouteStore) => obj.menuShow!)
        .map((obj: TblRouteStore) => ({
          routeId: obj.routeId,
          routeName: obj.routeName,
          routePath: obj.routePath,
          routeIcon: obj.routeIcon,
          parentRouteId: obj.parentRouteId,
          sortOrder: obj.sortOrder,
        }));
    },
  },
});

export const { setRoutes } = routesSlice.actions;
export const routesReducer = routesSlice.reducer;
