import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

// Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
import "./Assets/css/appStyle.css";
import "./Assets/css/styleG.css";
// i18n (needs to be bundled)
import "./i18n";

//DataTable css
import "@mantine/core/styles.layer.css";
import "mantine-datatable/styles.layer.css";

// Tailwind css
import "./tailwind.css";

import { MantineProvider, MantineColorScheme } from "@mantine/core";
import { Provider } from "react-redux";
import { store } from "./Storage";
// import router from "./Router";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "@fortawesome/fontawesome-svg-core/styles.css";
import Routes from "./Router/routes";
import { MainLoader } from "./Components/Common";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <MantineProvider defaultColorScheme='auto'>
    <Suspense fallback={<MainLoader />}>
      <Provider store={store}>
        <Routes />
        <ToastContainer />
      </Provider>
    </Suspense>
  </MantineProvider>
);