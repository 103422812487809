import PerfectScrollbar from "react-perfect-scrollbar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { toggleSidebar } from "../../../Storage/Redux/themeConfigSlice";
import { useState, useEffect } from "react";
import { IRootState } from "../../../Storage/Redux/store";
import { IconMenuDashboard } from "../../Icon/Menu";
import { TblRouteStore } from "../../../Interfaces/DataBase";
import { IconCaretsDown, IconMinus } from "../../Icon";
import { DynamicIconComp } from "../../HtmlInputs";

const Sidebar = () => {
  const [currentMenu, setCurrentMenu] = useState<string>("");
  const [errorSubMenu, setErrorSubMenu] = useState(false);
  const themeConfig = useSelector((state: IRootState) => state.themeConfig);
  const semidark = useSelector(
    (state: IRootState) => state.themeConfig.semidark
  );
  const routes: TblRouteStore[] = useSelector(
    (state: IRootState) => state.routesStore
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toggleMenu = (value: string) => {
    setCurrentMenu((oldValue) => {
      return oldValue === value ? "" : value;
    });
  };

  useEffect(() => {
    const selector = document.querySelector(
      '.sidebar ul a[href="' + window.location.pathname + '"]'
    );
    if (selector) {
      selector.classList.add("active");
      const ul: any = selector.closest("ul.sub-menu");
      if (ul) {
        let ele: any =
          ul.closest("li.menu").querySelectorAll(".nav-link") || [];
        if (ele.length) {
          ele = ele[0];
          setTimeout(() => {
            ele.click();
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1024 && themeConfig.sidebar) {
      dispatch(toggleSidebar());
    }
  }, [location]);

  return (
    <div className={semidark ? "dark" : ""}>
      <nav
        className={`sidebar fixed min-h-screen h-full top-0 bottom-0 w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] z-50 transition-all duration-300 ${
          semidark ? "text-white-dark" : ""
        }`}>
        <div className='bg-white dark:bg-black h-full'>
          <div className='flex justify-between items-center px-4 py-3'>
            <NavLink to='/' className='main-logo flex items-center shrink-0'>
              <img
                className='w-8 ml-[5px] flex-none animate-spin'
                src='/assets/images/logo.svg'
                alt='logo'
              />
              <span className='text-2xl ltr:ml-1.5 rtl:mr-1.5 font-semibold align-middle lg:inline dark:text-white-light'>
                {t("DTH Admin")}
              </span>
            </NavLink>

            <button
              type='button'
              className='collapse-icon w-8 h-8 rounded-full flex items-center hover:bg-gray-500/10 dark:hover:bg-dark-light/10 dark:text-white-light transition duration-300 rtl:rotate-180'
              onClick={() => dispatch(toggleSidebar())}>
              <IconCaretsDown className='m-auto rotate-90' />
            </button>
          </div>
          <PerfectScrollbar className='h-[calc(100vh-80px)] relative'>
            <ul className='relative font-semibold space-y-0.5 p-4 py-0'>
              <li className='menu nav-item'>
                <NavLink to='/' className='group'>
                  <div className='flex items-center'>
                    <IconMenuDashboard className='group-hover:!text-primary shrink-0' />
                    <span className='ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark'>
                      {t("Dashboard")}
                    </span>
                  </div>
                </NavLink>
              </li>

              {routes
                .filter((item) => item.parentRouteId === 0)
                .sort((a, b) => a.sortOrder! - b.sortOrder!)
                .map((item, index) => (
                  <>
                    <h2 className='py-3 px-7 flex items-center uppercase font-extrabold bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08] -mx-4 mb-1'>
                      <IconMinus className='w-4 h-5 flex-none hidden' />
                      <span>{t(item.routeName!)}</span>
                    </h2>

                    <li key={item.routeId!} className='nav-item'>
                      <ul>
                        {routes
                          .filter((obj) => obj.parentRouteId === item.routeId)
                          .sort((a, b) => a.sortOrder! - b.sortOrder!)
                          .map((obj) => (
                            <li key={obj.routeId!} className='nav-item'>
                              <NavLink to={obj.routePath!} className='group'>
                                <div className='flex items-center'>
                                  <svg
                                    width='20'
                                    height='20'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                    className='group-hover:!text-primary shrink-0'
                                    dangerouslySetInnerHTML={{
                                      __html: obj.routeIcon!,
                                    }}></svg>
                                  <span className='ltr:pl-3 rtl:pr-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark'>
                                    {t(obj.routeName!)}
                                  </span>
                                </div>
                              </NavLink>
                            </li>
                          ))}
                      </ul>
                    </li>
                  </>
                ))}
            </ul>
          </PerfectScrollbar>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
