import React, { lazy, useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { BlankLayout, DefaultLayout } from "../Components/Layouts";
import { RoutePIM } from "../Interfaces/PIMs";
import Dashboard from "../Pages/Dashboard";
import { AccessDenied } from "../Pages";
import { Error } from "../Components/Pages";
import { MainLoader } from "../Components/Common";
import { useGetRoutesQuery } from "../Apis/routeApi";
import { IRootState } from "../Storage/Redux/store";
import { userModel } from "../Interfaces";
import { useDispatch, useSelector } from "react-redux";
import { HttpStatusCode } from "axios";
import { setRoutes } from "../Storage/Redux/routesSlice";
import { TblRouteStore } from "../Interfaces/DataBase";
import { Login, ResetPassword } from "../Pages/Auth";

const Routes = () => {
  const dispatch = useDispatch();
  const [router, setRouter] = useState<any>(null);
  const { data, isLoading, refetch, error, isError } = useGetRoutesQuery(null);
  const userDetail: userModel = useSelector(
    (state: IRootState) => state.userAuthStore
  );
  const [dbRouteList, setDbRouteList] = useState<TblRouteStore[]>([]);

  useEffect(() => {
    refetch();
  }, [userDetail]);

  useEffect(() => {
    if (!isLoading) {
      if (isError) {
        if ("status" in error! && window.location.pathname !== "/auth/login") {
          if (error?.status === HttpStatusCode.Unauthorized) {
            localStorage.removeItem("token");
            window.location.replace("/auth/login");
          }
        }
      }
      setDbRouteList(data?.result || []);
    }
  }, [isLoading, data, error, isError]);

  useEffect(() => {
    const initializeRoutes = async () => {
      const staticRoutes: RoutePIM[] = [
        {
          path: "/auth/login",
          exact: true,
          element: <Login />,
          layout: "blank",
          title: "Login",
        },
        {
          path: "/auth/resetpassword/:code",
          exact: true,
          element: <ResetPassword />,
          layout: "blank",
          title: "Reset Password",
        },
        {
          path: "/",
          element: <Dashboard />,
          title: "Dashboard",
        },
        {
          path: "/accessDenied",
          element: <AccessDenied />,
          title: "Access Denied",
        },
        {
          path: "*",
          element: <Error />,
          title: "Error",
        },
      ];

      if (dbRouteList.length !== 0) {
        dispatch(setRoutes(dbRouteList));
        dbRouteList.forEach((obj) => {
          const Component = lazy(
            () => import(`../Pages/${obj.routeComponent!}`)
          );
          staticRoutes.push({
            path: obj.routePath!,
            title: obj.routeName!,
            exact: obj.routeExact!,
            element: <Component />,
          });
        });
      }

      const finalRoutes = staticRoutes.map((route) => ({
        ...route,
        element:
          route.layout === "blank" ? (
            <BlankLayout>{route.element}</BlankLayout>
          ) : (
            <DefaultLayout>{route.element}</DefaultLayout>
          ),
      }));

      setRouter(createBrowserRouter(finalRoutes));
    };

    initializeRoutes();
  }, [dbRouteList]);

  if (isLoading || !router || (localStorage.getItem("token") && !dbRouteList))
    return <MainLoader />;

  return <RouterProvider router={router} />;
};

export default Routes;
