import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SD_SPNameList } from "../Utility/StaticDatas";
import PackageMasterPIM from "../Interfaces/PIMs/PackageMasterPIM";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_APIBaseUrl}/AuthDataGet/ExecuteJson/${SD_SPNameList.DB_SP_Package}`, 
});

const SPTblPackageApi = createApi({
  reducerPath: "spTblPackageApi",
  baseQuery: async (args, api, extraOptions) => {
    try {
      const result = await baseQuery(args, api, extraOptions);
      return result;
    } catch (error: any) {
      if (error.status === 401) {
        localStorage.removeItem("token");
        window.location.replace("/auth/login");
      }
      throw error;
    }
  },
  tagTypes: ["Package"],
  endpoints: (builder) => ({
    insertPackage: builder.mutation({
      query: (data: PackageMasterPIM) => ({
        url: "1",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Package"],
    }),
  }),
});

export const {
  useInsertPackageMutation
} = SPTblPackageApi;
export default SPTblPackageApi;