import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Attribute,
  CourseDetails,
  DependentValues,
  Pricing,
  Structure,
  TermList,
} from "../../Interfaces/PIMs/CourseValue";
import { CreateCourse } from "../../Interfaces/PIMs";

export const initialCourseState: CreateCourse = {
  courseId: 0,
  courseName: "",
  courseShortName: "",
  courseType: [],
  term: "",
  structure: [],
  attributes: [],
  pricing: [],
  details: [],
  termList: [],
  dependentValues: [],
  priceDependentValues: [],
};

const courseSlice = createSlice({
  name: "course",
  initialState: initialCourseState,
  reducers: {
    addTermList: (state, action: PayloadAction<TermList[]>) => {
      state.termList = action.payload;
    },
    setCourse: (
      state,
      action: PayloadAction<{
        courseId: number;
        courseName: string;
        courseShortName: string;
        courseType: number[];
      }>
    ) => {
      state.courseId = action.payload.courseId;
      state.courseName = action.payload.courseName;
      state.courseShortName = action.payload.courseShortName;
      state.courseType = action.payload.courseType;
    },
    setCourseStructure: (
      state,
      action: PayloadAction<{ term: string; structure: Structure[] }>
    ) => {
      if (action.payload.term) {
        state.term = action.payload.term;
      }
      state.structure = [...action.payload.structure];
    },
    setCourseAttribute: (state, action: PayloadAction<Attribute[]>) => {
      state.attributes = [...action.payload];
    },
    setCoursePricing: (state, action: PayloadAction<Pricing[]>) => {
      state.pricing = [...action.payload];
    },
    setCourseDetails: (state, action: PayloadAction<CourseDetails[]>) => {
      state.details = [...action.payload];
    },
    setDependents: (state, action: PayloadAction<DependentValues>) => {
      if (state.dependentValues === undefined) {
        state.dependentValues = state.dependentValues;
      } else if (
        !state.dependentValues.find(
          (item) =>
            item.tblCourseDetail?.NodeID ===
            action.payload.tblCourseDetail?.NodeID
        )?.tblCourseDetail?.NodeID!
      ) {
        state.dependentValues = [...state.dependentValues, action.payload];
      } else {
        state.dependentValues.forEach((item) => {
          if (
            item.tblCourseDetail?.NodeID ===
            action.payload.tblCourseDetail?.NodeID
          ) {
            item.tblCourseDetail = {
              ...item.tblCourseDetail,
              NodeName: action.payload.tblCourseDetail?.NodeName!,
            };
          }
        });
      }
    },
    setPriceDependents: (state, action: PayloadAction<DependentValues>) => {
      if (state.priceDependentValues === undefined) {
        state.priceDependentValues = state.priceDependentValues;
      } else if (
        !state.priceDependentValues.find(
          (item) =>
            item.tblCourseDetail?.NodeID ===
            action.payload.tblCourseDetail?.NodeID
        )?.tblCourseDetail?.NodeID!
      ) {
        state.priceDependentValues = [
          ...state.priceDependentValues,
          action.payload,
        ];
      } else {
        state.priceDependentValues.forEach((item) => {
          if (
            item.tblCourseDetail?.NodeID ===
            action.payload.tblCourseDetail?.NodeID
          ) {
            item.tblCourseDetail = {
              ...item.tblCourseDetail,
              NodeName: action.payload.tblCourseDetail?.NodeName!,
            };
          }
        });
      }
    },
  },
});

export const {
  setCourse,
  setCourseStructure,
  addTermList,
  setCourseAttribute,
  setCoursePricing,
  setCourseDetails,
  setDependents,
  setPriceDependents,
} = courseSlice.actions;

export default courseSlice.reducer;
