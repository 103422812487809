import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SD_SPNameList } from "../Utility/StaticDatas";
import { CourseDetailModel } from "../Interfaces/PIMs";
import { WhatsAppDependentValues } from "../Interfaces/PIMs/WhatsApp";
import WhatsAppMasterPIM from "../Interfaces/PIMs/WhatsAppMasterPIM";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_APIBaseUrl}/AuthDataGet/ExecuteJson/${SD_SPNameList.DB_SP_WhatsAppDetails}`,
});

const SPTblWhatsAppDetailsApi = createApi({
  reducerPath: "spTblWhatsAppDetails",
  baseQuery: async (args, api, extraOptions) => {
    try {
      const result = await baseQuery(args, api, extraOptions);
      return result;
    } catch (error: any) {
      if (error.status === 401) {
        localStorage.removeItem("token");
        window.location.replace("/auth/login");
      }
      throw error;
    }
  },
  tagTypes: ["WhatsAppDetail"],
  endpoints: (builder) => ({
    insertWhatsAppDetail: builder.mutation({
      query: (data: WhatsAppDependentValues) => ({
        url: "1",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["WhatsAppDetail"],
    }),
    updateWhatsAppDetail: builder.mutation({
      query: (data: WhatsAppDependentValues) => ({
        url: "2",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["WhatsAppDetail"],
    }),
    deleteWhatsAppNode: builder.mutation({
      query: (data: WhatsAppMasterPIM) => ({
        url: "3",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["WhatsAppDetail"],
    }),
    getAllWhatsAppDetailByWhatsAppId: builder.query({
      query: (data: WhatsAppDependentValues) => ({
        url: "6",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      providesTags: ["WhatsAppDetail"],
    }),
    getWhatsAppDetailById: builder.query({
      query: (data: WhatsAppDependentValues) => ({
        url: "4",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      providesTags: ["WhatsAppDetail"],
    }),
  }),
});

export const {
  useInsertWhatsAppDetailMutation,
  useDeleteWhatsAppNodeMutation,
  useGetAllWhatsAppDetailByWhatsAppIdQuery,
  useUpdateWhatsAppDetailMutation,
} = SPTblWhatsAppDetailsApi;
export default SPTblWhatsAppDetailsApi;
