// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.spinner {
    width: 40px;
    height: 40px;
    position: relative;
  }
  
  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #3498db;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-bounce 2s infinite ease-in-out;
  }
  
  .double-bounce2 {
    animation-delay: -1s;
  }
  
  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Assets/css/cusLoader.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;;IAEE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,MAAM;IACN,OAAO;IAGP,4CAA4C;EAC9C;;EAEA;IAEE,oBAAoB;EACtB;;EAYA;IACE;;MAEE,mBAAmB;MACnB,2BAA2B;IAC7B;IACA;MACE,mBAAmB;MACnB,2BAA2B;IAC7B;EACF","sourcesContent":["\r\n.spinner {\r\n    width: 40px;\r\n    height: 40px;\r\n    position: relative;\r\n  }\r\n  \r\n  .double-bounce1,\r\n  .double-bounce2 {\r\n    width: 100%;\r\n    height: 100%;\r\n    border-radius: 50%;\r\n    background-color: #3498db;\r\n    opacity: 0.6;\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n  \r\n    -webkit-animation: sk-bounce 2s infinite ease-in-out;\r\n    animation: sk-bounce 2s infinite ease-in-out;\r\n  }\r\n  \r\n  .double-bounce2 {\r\n    -webkit-animation-delay: -1s;\r\n    animation-delay: -1s;\r\n  }\r\n  \r\n  @-webkit-keyframes sk-bounce {\r\n    0%,\r\n    100% {\r\n      -webkit-transform: scale(0);\r\n    }\r\n    50% {\r\n      -webkit-transform: scale(1);\r\n    }\r\n  }\r\n  \r\n  @keyframes sk-bounce {\r\n    0%,\r\n    100% {\r\n      transform: scale(0);\r\n      -webkit-transform: scale(0);\r\n    }\r\n    50% {\r\n      transform: scale(1);\r\n      -webkit-transform: scale(1);\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
