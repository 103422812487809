// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dragndrop .gu-transit {
    opacity: 0.2;
}
.dragndrop .selected > div {
    background-color: #E0E6ED;
}
.dark .dragndrop .selected > div {
    background-color: #0E1726;
}
.icon-change .left svg.feather-heart,
.icon-change .right svg.feather-star {
    display: none;
}
.dragndrop .sortable-chosen {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
:is(.dark .dragndrop .sortable-chosen) {
    --tw-bg-opacity: 1;
    background-color: rgb(14 23 38 / var(--tw-bg-opacity));
}
`, "",{"version":3,"sources":["webpack://./src/Assets/css/dragndrop.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;;IAEI,aAAa;AACjB;AAEI;IAAA,kBAA6B;IAA7B;AAA6B;AAA7B;IAAA,kBAA6B;IAA7B;AAA6B","sourcesContent":[".dragndrop .gu-transit {\r\n    opacity: 0.2;\r\n}\r\n.dragndrop .selected > div {\r\n    background-color: #E0E6ED;\r\n}\r\n.dark .dragndrop .selected > div {\r\n    background-color: #0E1726;\r\n}\r\n.icon-change .left svg.feather-heart,\r\n.icon-change .right svg.feather-star {\r\n    display: none;\r\n}\r\n.dragndrop .sortable-chosen {\r\n    @apply bg-white dark:bg-black;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
