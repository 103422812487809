import React, { PropsWithChildren } from "react";
import App from "../../Container/App";
import { withLoggedIn } from "../../HOC";

function BlankLayout({ children }: PropsWithChildren) {
  return (
    <App>
      <div className='text-black dark:text-white-dark min-h-screen'>
        {children}{" "}
      </div>
    </App>
  );
}

export default withLoggedIn(BlankLayout);
