import IconMenuApps from "./IconMenuApps";
import IconMenuAuthentication from "./IconMenuAuthentication";
import IconMenuCalendar from "./IconMenuCalendar";
import IconMenuCharts from "./IconMenuCharts";
import IconMenuChat from "./IconMenuChat";
import IconMenuComponents from "./IconMenuComponents";
import IconMenuContacts from "./IconMenuContacts";
import IconMenuDashboard from "./IconMenuDashboard";
import IconMenuDatatables from "./IconMenuDatatables";
import IconMenuDocumentation from "./IconMenuDocumentation";
import IconMenuDragAndDrop from "./IconMenuDragAndDrop";
import IconMenuElements from "./IconMenuElements";
import IconMenuFontIcons from "./IconMenuFontIcons";
import IconMenuForms from "./IconMenuForms"; 
import IconMenuInvoice from "./IconMenuInvoice";
import IconMenuMailbox from "./IconMenuMailbox";
import IconMenuMore from "./IconMenuMore";
import IconMenuNotes from "./IconMenuNotes";
import IconMenuPages from "./IconMenuPages";
import IconMenuScrumboard from "./IconMenuScrumboard";
import IconMenuTables from "./IconMenuTables";
import IconMenuTodo from "./IconMenuTodo";
import IconMenuUsers from "./IconMenuUsers";
import IconMenuWidgets from "./IconMenuWidgets";

export {IconMenuApps, IconMenuAuthentication,IconMenuCalendar,IconMenuCharts,IconMenuChat,IconMenuComponents,IconMenuContacts,IconMenuDashboard,IconMenuDatatables,IconMenuDocumentation,IconMenuDragAndDrop,IconMenuElements,IconMenuFontIcons,IconMenuForms,IconMenuInvoice,IconMenuMailbox,IconMenuMore,IconMenuNotes,IconMenuPages,IconMenuScrumboard,IconMenuTables,IconMenuTodo,IconMenuUsers,IconMenuWidgets };