// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dark .markdown-editor .editor-toolbar i.separator {
    border-color: #888ea8;
    border-right: none;
}

.dark .markdown-editor .EasyMDEContainer .CodeMirror,
.dark .markdown-editor .editor-preview {
    background-color: #121e32;
}

.markdown-editor .editor-toolbar,
.markdown-editor .EasyMDEContainer .CodeMirror{
    border-color: #ccc;
    color: #0e1726;
}
.dark .markdown-editor .editor-toolbar,
.dark .markdown-editor .EasyMDEContainer .CodeMirror {
    border-color: #17263c;
    color: #888ea8 !important;
}

.dark .markdown-editor .editor-toolbar button.active, .editor-toolbar button:hover {
    background-color: transparent !important;
    border-color: transparent !important;
    color: #4361ee;
}
`, "",{"version":3,"sources":["webpack://./src/Assets/css/markdown-editor.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;;IAEI,kBAAkB;IAClB,cAAc;AAClB;AACA;;IAEI,qBAAqB;IACrB,yBAAyB;AAC7B;;AAEA;IACI,wCAAwC;IACxC,oCAAoC;IACpC,cAAc;AAClB","sourcesContent":[".dark .markdown-editor .editor-toolbar i.separator {\r\n    border-color: #888ea8;\r\n    border-right: none;\r\n}\r\n\r\n.dark .markdown-editor .EasyMDEContainer .CodeMirror,\r\n.dark .markdown-editor .editor-preview {\r\n    background-color: #121e32;\r\n}\r\n\r\n.markdown-editor .editor-toolbar,\r\n.markdown-editor .EasyMDEContainer .CodeMirror{\r\n    border-color: #ccc;\r\n    color: #0e1726;\r\n}\r\n.dark .markdown-editor .editor-toolbar,\r\n.dark .markdown-editor .EasyMDEContainer .CodeMirror {\r\n    border-color: #17263c;\r\n    color: #888ea8 !important;\r\n}\r\n\r\n.dark .markdown-editor .editor-toolbar button.active, .editor-toolbar button:hover {\r\n    background-color: transparent !important;\r\n    border-color: transparent !important;\r\n    color: #4361ee;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
