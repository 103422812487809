// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-border {
  border-left: 5px solid rgb(255, 190, 51);
}
.left-border > h2 {
  margin-left: 15px;
}
.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Assets/css/appStyle.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;AAC1C;AACA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".left-border {\r\n  border-left: 5px solid rgb(255, 190, 51);\r\n}\r\n.left-border > h2 {\r\n  margin-left: 15px;\r\n}\r\n.fill {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  overflow: hidden;\r\n}\r\n.fill img {\r\n  flex-shrink: 0;\r\n  min-width: 100%;\r\n  min-height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
