import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SD_SPNameList } from "../Utility/StaticDatas";
import { CourseMasterPIM } from "../Interfaces/PIMs";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_APIBaseUrl}/AuthDataGet/ExecuteJson/${SD_SPNameList.DB_SP_Course}`, 
});

const SPTblCourseApi = createApi({
  reducerPath: "spTblCourseApi",
  baseQuery: async (args, api, extraOptions) => {
    try {
      const result = await baseQuery(args, api, extraOptions);
      return result;
    } catch (error: any) {
      if (error.status === 401) {
        localStorage.removeItem("token");
        window.location.replace("/auth/login");
      }
      throw error;
    }
  },
  tagTypes: ["Courses"],
  endpoints: (builder) => ({
    insertCourse: builder.mutation({
      query: (data: CourseMasterPIM) => ({
        url: "1",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Courses"],
    }),
    updateCourse: builder.mutation({
      query: (data: CourseMasterPIM) => ({
        url: "2",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Courses"],
    }),
    deleteCourse: builder.mutation({
      query: (data: CourseMasterPIM) => ({
        url: "3",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Courses"],
    }),
    getCourseById: builder.query({
      query: (data: CourseMasterPIM) => ({
        url: "4",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      providesTags: ["Courses"],
    }),
    getAllCourse: builder.query({
      query: () => ({
        url: "4",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: {},
      }),
      providesTags: ["Courses"],
    }),
    toggleActivationCourse: builder.mutation({
      query: (data: CourseMasterPIM) => ({
        url: "5",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Courses"],
    }),
    removeCourseType: builder.mutation({
      query: (data: CourseMasterPIM) => ({
        url: "6",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Courses"],
    }),
  }),
});

export const {
  useInsertCourseMutation,
  useUpdateCourseMutation,
  useDeleteCourseMutation,
  useGetCourseByIdQuery,
  useGetAllCourseQuery,
  useToggleActivationCourseMutation,
  useRemoveCourseTypeMutation,
} = SPTblCourseApi;
export default SPTblCourseApi;
