import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RouteDTO } from "../Interfaces/DTOs";

const routeApi = createApi({
  reducerPath: "routeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_APIBaseUrl}/router`,
  }),
  tagTypes: ["Routes"],
  endpoints: (builder) => ({
    getRoutes: builder.query({
      query: () => ({
        url: "",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
      providesTags: ["Routes"],
    }),
    getAllRoutes: builder.query({
      query: () => ({
        url: "allRoutes",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
      providesTags: ["Routes"],
    }),
    postRoutes: builder.mutation({
      query: (data: RouteDTO) => ({
        url: "",
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Routes"],
    }),
    putRoutes: builder.mutation({
      query: (data: RouteDTO) => ({
        url: `${data.routeId}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Routes"],
    }),
    changeRoute: builder.mutation({
      query: ({ id, parentId }: { id: number; parentId: number }) => ({
        url: `changeparent/${id}/${parentId}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json",
        },
      }),
      invalidatesTags: ["Routes"],
    }),
  }),
});

export const {
  useGetRoutesQuery,
  useGetAllRoutesQuery,
  usePostRoutesMutation,
  usePutRoutesMutation,
  useChangeRouteMutation,
} = routeApi;
export default routeApi;
