import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  CreateWhatsApp,
  WhatsAppDependentValues,
  WhatsAppStructure,
} from "../../Interfaces/PIMs/WhatsApp";
import WhatsAppMasterPIM from "../../Interfaces/PIMs/WhatsAppMasterPIM";

export const initialCourseState: CreateWhatsApp = {
  whatsAppId: "",
  whatsAppName: "",
  whatsAppStructure: [],
  whatsAppDependentValues: [],
};

const whatsAppSlice = createSlice({
  name: "whatsApp",
  initialState: initialCourseState,
  reducers: {
    setWhatsAppName: (state, action : PayloadAction<{ whatsAppName: string; whatsAppId: string }>) => {
      state.whatsAppName = action.payload.whatsAppName
      state.whatsAppId = action.payload.whatsAppId
    },
    setWhatsAppStructure: (
      state,
      action: PayloadAction<{ structure: WhatsAppStructure[] }>
    ) => {
      state.whatsAppStructure = [...action.payload.structure];
    },
    setWhatsAppDependents: (state, action: PayloadAction<WhatsAppMasterPIM>) => {
      if (state.whatsAppDependentValues === undefined) {
        state.whatsAppDependentValues = state.whatsAppDependentValues;
      } else if (
        !state.whatsAppDependentValues.find(
          (item) =>
            item.tblMasterWhatsAppDetails?.WaNodeID ===
            action.payload.tblMasterWhatsAppDetails?.WaNodeID
        )?.tblMasterWhatsAppDetails?.WaNodeID!
      ) {
         state.whatsAppDependentValues = [...state.whatsAppDependentValues, action.payload];
      } else {
        state.whatsAppDependentValues.forEach((item) => {
          if (
            item.tblMasterWhatsAppDetails?.WaNodeID ===
            action.payload.tblMasterWhatsAppDetails?.WaNodeID
          ) {
            item.tblMasterWhatsAppDetails = {
              ...item.tblMasterWhatsAppDetails,
              WaNodeName: action.payload.tblMasterWhatsAppDetails?.WaNodeName!,
            };
          }
        });
      }
    },
  },
});

export const {
  setWhatsAppName,
  setWhatsAppStructure,
  setWhatsAppDependents,
} = whatsAppSlice.actions;

export default whatsAppSlice.reducer;
