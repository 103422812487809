import { createSlice } from "@reduxjs/toolkit";
import { ResetPasswordDTO } from "../../Interfaces/DTOs";

export const emptyUser: ResetPasswordDTO = {
  Email: "",
  PhoneNumber: "",
};

export const resetUserSlice = createSlice({
  name: "resetUser",
  initialState: emptyUser,
  reducers: {
    setUser: (state, action) => {
      state.Email = action.payload.Email;
      state.PhoneNumber = action.payload.PhoneNumber;
    },
  },
});

export const { setUser } = resetUserSlice.actions;
export const resetUserReducer = resetUserSlice.reducer;
