import { useEffect, useState } from "react";
import { useAuthenticateUserMutation } from "../Apis/authApi";
import { apiResponse } from "../Interfaces";
import { useNavigate } from "react-router-dom";
import { MainLoader } from "../Components/Common";

const withAuth = (WrappedComponent: any) => {
  return (props: any) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [authenticate, setAuthenticate] = useState(false);
    const [authUser] = useAuthenticateUserMutation();

    useEffect(() => {
      const checkAuth = async () => {
        const response: apiResponse = await authUser(null);
        setAuthenticate(response.data?.isSuccess!);
        setIsLoading(false);
      };

      checkAuth();
    }, []);

    if (isLoading) {
      return <MainLoader />;
    }
    if (!authenticate) {
      navigate("/auth/login", { replace: true });
      return null;
    }
    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
